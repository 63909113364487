@import "./_global.scss";

.contact-us-map-container {
    padding-top: 2.5rem;
}

.contact-us-map-embed-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-us-map-embed {
    width: 90%;

    @media screen and (max-width: $phone-wide) {
        width: 100%;
    }
}