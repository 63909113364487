@import "./_global.scss";

$header-logo-screen-max: 200px;
$header-logo-phone-max: 175px;

.header-container {
    display: flex;
    align-items: center;
    background-color: #fee700;
    height: $header-height;
    position: fixed;
    width: 100%;
    z-index: 9999; 
    filter: drop-shadow(.25rem 0 .125rem rgb(175, 175, 175));

    img {
        margin-top: .15rem;
        filter: drop-shadow(.125rem 0 .125rem rgb(175, 175, 175));
    }
    
    @media screen and (max-width: $phone-wide) {
        height: $header-height-phone;

        img {
            margin-top: 0;
        }
    }
}

.header-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-logo-container {
    display: flex;
    flex-grow: 1;
}

.header-logo {
    max-width: $header-logo-screen-max;

    @media screen and (max-width: $phone-wide) {
        max-width: $header-logo-phone-max;
    }
}

.header-nav-container {
    display: flex;
}

.header-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    font-weight: bold;
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
        color: $primary-dark-blue;
        text-decoration: none;
    }
    a:hover {
        color: $primary-red;
    }
    a:active {
        color: $primary-red;
    }
    a:focus {
        color: $primary-red;
    }
    li {
        margin-left: 2rem;
    }

    @media screen and (min-width: $tablet-wide-plus-1) {
        display: flex;
    }

    @media (max-width: $tablet-wide) {
        display: none;
    }
}

.header-nav-toggle {
    display: flex;
    background-color: transparent;
    border: .5px solid #808080;
    border-radius: 8px;
    font-size: 2rem;
    font-weight: normal;
    margin-right: .25rem;

    @media screen and (min-width: $tablet-wide-plus-1) {
        display: none;
    }

    @media screen and (max-width: $phone-wide) {
        display: flex;
    }
}

.header-dropdown-nav {
    display: none;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0 .1875rem .375rem #808080;
    border-radius: .5rem;
    font-weight: bold;
    list-style-type: none;
    margin: 0;
    padding: .25rem .5rem .25rem 1rem;
    position: absolute;
    top: 6.5rem;
    right: 1.25rem;
    width: 10rem;

    a {
        color: $primary-dark-blue;
        text-decoration: none;
    }
    a:hover {
        color: $primary-red;
    }
    a:active {
        color: $primary-red;
    }
    a:focus {
        color: $primary-red;
    }
    li {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }

    @media screen and (min-width: $tablet-wide) {
        display: none;
    }
}

.header-nav-selected {
    color: $primary-red;
}