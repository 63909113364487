@import "./_global.scss";

.page-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $primary-dark-blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-right: 1rem;
    padding-left: 1rem;
    height: 600px;

    img {
        max-width: 750px;
        margin-top: 7rem;
    }

    @media screen and (max-width: $tablet-wide) {
        height: 450px;

        img {
            margin-top: 5rem;
            max-width: 550px;
        }
    }

    @media screen and (max-width: $phone-wide) {
        height: 300px;

        img {
            max-width: 80%;
            margin-top: 4.5rem;
        }
    }
}

.page-hero-title {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0 0;
    text-align: left;
    text-shadow: 2px 2px 2px $primary-dark-blue;

    h1 {
        color: #ffffff;
    }

    @media screen and (max-width: $tablet-wide) {
        margin: 0;
        text-align: center;
    }
}