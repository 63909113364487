@import "./_global.scss";

$footer-height: 300px;

$footer-logo-screen-max: 200px;
$footer-logo-tablet-max: 175px;

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    min-height: $footer-height;
}

.footer-flex-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-logo-container {
    display: flex;
}

.footer-logo {
    width: $footer-logo-screen-max;

    @media screen and (max-width: $phone-wide) {
        width: $footer-logo-tablet-max;
    }
}

.footer-address-container {
    color: #ffffff;
    font-size: .9rem;
    padding-top: 1rem;
    padding-left: .5rem;
}

.footer-copyright-text {
    color: hsla(0, 0%, 100%, .5);
    font-size: .75rem;
    line-height: 1.125rem;
    margin-top: 2rem;

    a {
        color: #a0a0a0;
    }
}

.footer-social-icon {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;

    a {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        color: #ffffff;
        margin-left: .25rem;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    svg {
        color: #ffffff;
        font-size: 2.5rem;
        margin-right: .5rem;
    }
}