@import "./_global.scss";

.contact-us-hours-container {
    padding-top: 2.5rem;
}

.contact-us-hours-table-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-us-hours-table {
    font-size: 1.125rem;
    max-width: 500px;
    width: 500px;

    td {
        line-height: 2.5rem;
    }

    @media screen and (max-width: $phone-wide) {
        flex-direction: column;
        width: 100%;
    }
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}