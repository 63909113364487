@import "./_global.scss";

$image-width: 34.375rem;
$image-height: 25.781rem;
$ul-height-screen: 13rem;
$ul-width-screen: 17rem;

.main-product-container {
    padding-top: 1.5rem;
}

.main-product-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.75rem;
}

.main-product-card-product-container {
    display: flex;
    flex-direction: row;
    background-color: #f9f9f1;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;

    img {
        border-radius: .25rem;
        height: $image-height;
    }

    @media screen and (max-width: $tablet-wide) {
        flex-direction: column;
        
        img {
            height: 100%;
        }

    }
}

.main-product-card-product-text {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: .25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.main-product-card-product-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.main-product-card-product-descr {
    font-size: 1rem;
    line-height: 1.5rem;
}

.main-product-card-product-flavors {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 1.35rem;
    max-height: $ul-height-screen;
    padding-left: 1.125rem;

    li {
        width: $ul-width-screen;
    }

    @media screen and (max-width: $phone-wide) {
        max-height: 100%;

        li {
            width: 100%;
        }
    }
}

.main-product-card-product-pricing-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 330px;
}

.main-product-card-product-price-tag {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    width: 110px;
    text-align: left;
}

.main-product-card-product-price-line {
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

.main-product-card-product-price-line-label {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    width: 200px;
    text-align: left;
}

.main-product-card-product-pricing {
    color: red;
    font-size: 1.125rem;
}