@import "./_global.scss";
@import "./forms.scss";

.contact-us-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #ffffff;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    width: 100%;

    @media screen and (max-width: $phone-wide) {
        justify-content: center;
    }
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    width: 40%;

    @media screen and (max-width: $tablet-wide) {
        width: 60%;
    }

    @media screen and (max-width: $phone-wide) {
        align-self: center;
        margin-bottom: 1.5rem;
        margin-left: 0;
        width: 95%;
    }
}

.contact-us-form-input-field {
    margin-right: 1rem;
    margin-bottom: .75rem;
    
    input, textarea {
        width: 100%;
    }

    textarea {
        height: 9.375rem;
        padding-top: .25rem;
    }

    @media screen and (max-width: $phone-wide) {
        margin-right: 0;
        margin-bottom: 1rem;
    }
} 

.contact-us-form-input-field-error {
    height: 1rem;
}

.contact-us-form-button {
    align-self: flex-start;

    @media screen and (max-width: $phone-wide) {
        align-self: flex-start;
    }
}