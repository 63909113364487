@import "./_global.scss";

// form variables
$input-field-height: 3.5rem;
$input-field-border-radius: .25rem;
$submit-button-border-radius: .5rem;

// button variables
$action-color: $primary-light-blue;
$action-hover-color: #182129;
$danger-color: #FF5C5C;
$danger-hover-color: #BF4545;
$cancel-color: #EDEDED;
$cancel-hover-color: #BFBFBF;

input {
    border: 1px solid #0000001F;
}

input:not([type="checkbox"]),
textarea,
select {
    border: 1px solid #0000001F;
    border-radius: $input-field-border-radius;
    outline: none;
    height: $input-field-height;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media screen and (max-width: $phone-wide) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

input:focus,
textarea:focus,
select:focus {
    border-color: $primary-dark-blue;
}

label {
    color: #575757;
    display: block;
    font-size: 0.75rem;
    margin-left: .5rem;
    margin-bottom: .25rem;
}

.validation-error {
    color: #ffffff;
    display: block;
    font-size: 0.75rem;
    margin-top: .25rem;
    margin-left: .5rem;
    margin-bottom: .25rem;
}

.form-submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $action-color;
    border: 0rem;
    border-radius: $submit-button-border-radius;
    color: #FFFFFF;
    font-weight: bold;
    min-height: $input-field-height;
    min-width: 8rem;
    padding: .75rem;
    text-transform: uppercase;

    &:hover {
        background-color: $action-hover-color;
        cursor: pointer;
    }
    &:disabled {
        background-color: $action-hover-color;
        color: #ededed;
        cursor: default;
    }
}

.form-required-asterisk {
    color: red;
}