.snackbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 720px;
    height: 50px;
    background-color: #000000;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000040;
    z-index: 9999; 
}

.snackbar-message {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    svg {
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }
    span {
        font-size: 15px;
        color: #FFFFFF;
    }
}

.snackbar-action {
    min-width: 50px;
    background-color: #000000;
    color: #FFFFFF;
    font-weight: bold;
    text-transform: uppercase;
}